import React, { useState, useEffect, useRef } from 'react';

const CameraApp = () => {
  const [cameraStream, setCameraStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedVideoBlob, setRecordedVideoBlob] = useState(null);
  const [playedVideoBlob, setPlayedVideoBlob] = useState(null);
  const videoRef = useRef(null);
  const playedVideoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const getCameraStream = async () => {
      try {
        // Check if the browser supports the MediaDevices API
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          throw new Error('Webcam access is not supported in this browser.');
        } else {
          // Get the camera stream
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });

          // Save the camera stream to state
          setCameraStream(stream);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getCameraStream();

    // Cleanup the camera stream when the component unmounts
    return () => {
      if (cameraStream) {
        cameraStream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (cameraStream && videoRef.current) {
      // Set the camera stream as the source of the video element
      videoRef.current.srcObject = cameraStream;
    }
  }, [cameraStream]);

  useEffect(() => {
    if (playedVideoBlob && playedVideoRef.current) {
      // Create a URL for the played video Blob
      const videoURL = URL.createObjectURL(playedVideoBlob);

      // Set the played video URL as the source of the played video element
      playedVideoRef.current.src = videoURL;
    }
  }, [playedVideoBlob]);

  const startRecording = () => {
    try {
      // Check if the camera stream is available
      if (!cameraStream) {
        throw new Error('Camera stream is not available.');
      }

      // Create a new MediaRecorder instance
      const mediaRecorder = new MediaRecorder(cameraStream);

      // Create an array to store video data
      const chunks = [];

      // Event listener when data is available
      mediaRecorder.ondataavailable = event => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      // Event listener when recording stops
      mediaRecorder.onstop = () => {
        // Combine all video data into a single Blob
        const videoData = new Blob(chunks, { type: mediaRecorder.mimeType });

        // Save the video data to state
        setRecordedVideoBlob(videoData);
        setPlayedVideoBlob(videoData);

        // Clear the chunks array
        chunks.length = 0;
      };

      // Set the MediaRecorder instance to the ref
      mediaRecorderRef.current = mediaRecorder;

      // Start recording
      mediaRecorder.start();

      // Update the recording state
      setIsRecording(true);
    } catch (error) {
      console.error(error);
    }
  };

  const stopRecording = () => {
    try {
      // Check if recording is in progress
      if (!isRecording) {
        throw new Error('No ongoing recording to stop.');
      }

      // Stop the MediaRecorder
      mediaRecorderRef.current.stop();

      // Update the recording state
      setIsRecording(false);
    } catch (error) {
      console.error(error);
    }
  };

  const saveRecording = () => {
    try {
      // Check if a recorded video is available
      if (!recordedVideoBlob) {
        throw new Error('No recorded video available.');
      }

      // Create a new Blob with the recorded video data
      const videoFile = new File([recordedVideoBlob], 'recorded-video.webm', {
        type: 'video/webm',
      });

      // Save the video file to the device
      const downloadURL = URL.createObjectURL(videoFile);

      // Create a new anchor element
      const downloadLink = document.createElement('a');

      // Set the download link attributes
      downloadLink.href = downloadURL;
      downloadLink.download = 'recorded-video.webm';

      // Append the anchor element to the DOM
      document.body.appendChild(downloadLink);

      // Programmatically trigger the click event on the anchor element
      downloadLink.click();

      // Clean up the anchor element
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileSelect = event => {
    try {
      // Retrieve the selected file
      const file = event.target.files[0];

      // Check if a file is selected
      if (!file) {
        throw new Error('No file selected.');
      }

      // Save the selected file Blob
      setPlayedVideoBlob(file);

      // Reset the input to allow selecting the same file again
      event.target.value = '';
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div>
        {playedVideoBlob ? (
          <video ref={playedVideoRef} width="640" height="480" controls autoPlay muted />
        ) : (
          <video ref={videoRef} width="640" height="480" autoPlay muted />
        )}
      </div>
      <div>
        {!isRecording ? (
          <button onClick={startRecording}>Start Recording</button>
        ) : (
          <button onClick={stopRecording}>Stop Recording</button>
        )}
        <button onClick={saveRecording}>Save Recording</button>
        <input
          type="file"
          accept="video/*"
          onChange={handleFileSelect}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <button onClick={() => fileInputRef.current.click()}>Select Video File</button>
      </div>
    </div>
  );
};

export default CameraApp;
