import React, { useState, useRef, useEffect } from 'react';

const MovingDot = () => {
  const [speed, setSpeed] = useState(1);
  const [size, setSize] = useState(20);
  const [position, setPosition] = useState(0);
  const [maxPosition, setMaxPosition] = useState(0);
  const [isMoving, setIsMoving] = useState(false);
  const requestRef = useRef();
  const dotRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      setMaxPosition(window.innerWidth - size);
      setPosition((prevPosition) => {
        if (prevPosition > maxPosition) {
          return maxPosition;
        }
        return prevPosition;
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(requestRef.current);
    };
  }, [size, maxPosition]);

  useEffect(() => {
    if (!isMoving) {
      cancelAnimationFrame(requestRef.current);
      return;
    }

    const moveDot = () => {
      setPosition((prevPosition) => {
        const nextPosition = prevPosition + speed;
        if (nextPosition > maxPosition) {
          return 0;
        }
        return nextPosition;
      });
      requestRef.current = requestAnimationFrame(moveDot);
    };

    requestRef.current = requestAnimationFrame(moveDot);

    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, [speed, maxPosition, isMoving]);

  const handleSpeedChange = (event) => {
    setSpeed(Number(event.target.value));
    setIsMoving(true);
  };

  const handleSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setSize(newSize);
    setMaxPosition(window.innerWidth - newSize);

    if (isMoving) {
      setIsMoving(false);
      setTimeout(() => {
        setIsMoving(true);
      }, 100); // Delay to ensure smooth transition
    }
  };

  return (
    <div style={{ backgroundColor: 'black', height: '20vh' }}>
      <div style={{ backgroundColor: 'black', paddingTop: '20px', color: '#fff' }}>
        <label htmlFor="speed">Speed:</label>
        <input
          id="speed"
          type="number"
          min="1"
          max="10"
          value={speed}
          onChange={handleSpeedChange}
        />
        <label htmlFor="size" style={{ marginLeft: '10px' }}>Size:</label>
        <input
          id="size"
          type="number"
          min="10"
          max="100"
          value={size}
          onChange={handleSizeChange}
        />
      </div>
      <div>
        <div
          ref={dotRef}
          style={{
            backgroundColor: 'red',
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: '50%',
            position: 'absolute',
            left: `${position}px`,
            top: '30%',
            transform: 'translateY(-50%)',
          }}
        />
      </div>
    </div>
  );
};

export default MovingDot;
