import React from 'react';
import MovingDot from './MovingDot';
import CameraDetector from './CameraDetector';

const App = () => {
  return (
    <div>
      <h1>MovingDot</h1>
        <MovingDot />
      <h1>CameraDetector</h1>
      <CameraDetector />
    </div>
  );
};

export default App;
